import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import '../../styles/common.scss';
import '../../styles/icon.scss';

const IndexPage = () => {
  useEffect(() => {
    navigate('/admin/overview/');
  }, []);
  return <></>;
};

export default IndexPage;
